<template>
  <fluency-loader v-if="loading"></fluency-loader>
  <div v-else-if="analysis" class="campaign-analysis" :style="{ 'min-height': containerHeight + 'px' }">

<!--    status bar navigation-->
    <span v-if="insideMuse && campaignPlan && campaignPlan.name">Campaign: {{campaignPlan.name}}</span>

    <div class="d-flex py-5">
      <div v-if="statusBar" class="d-flex w-100 status-bar-container" :class="{'w-75': !insideMuse}" ref="statusBarContainer">
        <div v-for="section in statusBar"
             :key="section.text"
             class="status-navigation d-flex flex-column align-items-center"
             :class="{'border-secondary': activeSectionName === section.section}"
             @click="activeSectionName = section.section"
             v-p-tooltip.top="$filters.convertFromCamelCase(section.text)"
        >
          <div class="status-indicator" :class="`bg-${section.variant}`">
            <fluency-icon v-if="section.icon" :type="section.icon" class="text-white"></fluency-icon>
          </div>
          <span class="mb-2 nav-text" :class="{'small': insideMuse}">{{$filters.convertFromCamelCase(section.text) }}</span>
        </div>
      </div>
      <div class="d-flex justify-content-end align-items-center" :class="{'w-25': !insideMuse}" v-if="!insideMuse">
        <img v-if="campaignPlan.contentTemplateId"
             src="core-ui/assets/img/robots/full-white.png"
             width="20"
             height="25"
             v-p-tooltip.top="'This Campaign is linked to a blueprint.'"/>
        <b-button class="ml-5" @click="fetchAnalysis">
          Re-Analyze
          <fluency-icon type="loop"></fluency-icon>
        </b-button>
      </div>
    </div>

    <div v-if="this.activeSection">

<!--      messages-->

      <div class="d-flex align-items-start">
        <t-card class="mr-4" :class="{'w-75': !insideMuse}" :title="$filters.convertFromCamelCase(activeSectionName)">

            <div v-for="(message, index) in activeSection.checklistMessages"
                 :key="`checklist-message-${index}`" :class="{'border-top pt-4': index !== 0, 'text-muted': message.disabled}">
              <h5 v-if="!message.disabled" class="mb-3">
                <b-badge :variant="healthMap[message.health]">
                  <fluency-icon v-if="message.health === 'IDEA'" type="suggestion" class="mr-1" />
                  {{$filters.convertFromCapsUnderscore(healthTextMap(message.health))}}
                </b-badge>
              </h5>
              <div v-markdown="message.notice" />

              <!-- FLUENCY MUSE SUGGESTIONS - FOR `SMX` PRESENTATION IN JUNE 2023 -->
              <div v-if="message.health === 'IDEA'" class="mb-4">
                <div>
                  <b-btn variant="link"
                         class="p-0"
                         @click="toggleCollapsibleCard(index)">
                    {{(showCollapsibleCard[index]) ? 'Dismiss' : 'Get' }} Suggestions
                    <fluency-icon v-if="loadingCollapsibleCard[index] === true" type="loop" class="rotating" />
                  </b-btn>
                </div>
                <div class="mt-3 ml-3">
                  <t-collapse :visible="showCollapsibleCard[index] && loadingCollapsibleCard[index] === false">
                    <table class="table table-striped table-hover">
                      <thead>
                      <tr>
                        <th><b-checkbox @change="smxCheckboxAll($event, index)" /></th>
                        <th>Keyword</th>
                        <th>Quality Score</th>
                        <th>Est. Clicks/mo.</th>
                        <th>Avg. Bid</th>
                        <th>Reason For Bid Amount</th>
                      </tr>
                      </thead>
                      <tbody>
                      <tr v-for="(suggestion, suggestionKey) in message.data" :key="`${index}-${suggestionKey}`">
                        <td>
                          <b-checkbox :checked="Array.isArray(smxToApply[index]) && smxToApply[index].includes(suggestion.keyword)"
                                      @change="smxCheckbox($event, index, suggestion.keyword)" />
                        </td>
                        <td>{{suggestion.keyword}}</td>
                        <td>{{suggestion.qualityScore}}</td>
                        <td>{{suggestion.estimatedClicksPerMonth}}</td>
                        <td>{{$filters.currency(suggestion.averageBid, 2)}}</td>
                        <td>{{suggestion.reasonForBidAmount}}</td>
                      </tr>
                      </tbody>
                    </table>

                    <div class="text-right">
                      <b-button v-for="action in message.actions"
                                :key="`btn-${action.text}`"
                                variant="primary"
                                :disabled="message.disabled || (Array.isArray(smxToApply[index]) && smxToApply[index].length === 0)"
                                @click="smxAction(action, index)">{{action.actionText}}</b-button>
                    </div>
                  </t-collapse>
                </div>
              </div>

              <div v-else-if="message.health !== 'HEALTHY'" class="d-flex mb-4">
                <div class="mr-5">
                  Impact:
                  <template v-if="message.impactCategory === 'SPEND'">
                    <b>Spend $</b>
                  </template>
                  <template v-else-if="message.impactCategory === 'PERFORMANCE'">
                    <b>Performance</b>
                    <fluency-icon type="performance" />
                  </template>
                  <template v-else>
                    {{message.impactCategory}}
                  </template>
                </div>

                <b-button v-for="action in message.actions"
                          :key="`btn-${action.text}`"
                          variant="link"
                          :disabled="message.disabled"
                          class="p-0"
                          @click="performAction(action, index)">{{action.actionText}}</b-button>
              </div>
            </div>

            <!--        google map-->

            <div v-if="activeSection.geoTargetDTO" class="mt-5">
              <geo-target-editor v-model="activeSection.geoTargetDTO"
                                 :account-plan="analysis.accountPlan"
                                 :preview-mode="insideMuse"
                                 :advertising-channel-id="null" />
            </div>

        </t-card>

        <t-card class="w-25" :class="{'d-none': insideMuse}">

<!--          stats-->

            <div v-for="(stat, index) in stats"
                 class="p-4"
                 :class="{'border-top': index !== 0}"
                 :key="stat.label">
              <div>{{$filters.convertFromCamelCase(stat.label)}}:</div>
              <h1 class="font-weight-bold text-muted m-0"
                  v-html="stat.formatter(stat.value, stat.prop1, stat.prop2, stat.prop3)"></h1>
            </div>

        </t-card>

      </div>
    </div>

  </div>
</template>

<script>
import _cloneDeep from 'lodash.clonedeep'
import _delay from 'lodash.delay'
import FluencyLoader from 'core-ui/components/common/fluencyLoader'
import GeoTargetEditor from '@/components/pages/manage/geoTargetEditor'

export default {
  name: 'AnalyzeCampaign',
  components: { FluencyLoader, GeoTargetEditor },
  props: {
    campaignPlanProp: {
      type: Object
    },
    insideMuse: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      analysis: undefined,
      statusBar: undefined,
      loading: false,
      activeSectionName: undefined,
      healthMap: {
        HEALTHY: 'success',
        ALERT: 'danger',
        NEEDS_REVIEW: 'warning',
        IDEA: 'info'
      },
      showCollapsibleCard: {},
      loadingCollapsibleCard: {},
      smxToApply: {},
      sections: ['campaignPartnerReview', 'budgetReview', 'geoReview', 'adGroupReview', 'creativeReview', 'keywordReview'],
      containerHeight: 100
    }
  },
  computed: {
    campaignPlan () {
      if (this.campaignPlanProp) {
        return this.campaignPlanProp
      }
      const item = this.$store.getters.settingsItem || this.$store.getters.activeItem
      if (item?.campaignPlanId) {
        return item
      }
      return {}
    },
    campaignPlanId () {
      return this.campaignPlan.campaignPlanId
    },
    activeSection () {
      return this.analysis[this.activeSectionName]
    },
    activeVariant () {
      return this.statusBar?.find(section => section.section === this.activeSectionName)?.variant
    },
    stats () {
      const flatSection = { ...this.activeSection, ...this.activeSection.metrics }
      return Object.entries(flatSection).map(entry => {
        const [key, value] = entry
        if (typeof value !== 'number' && typeof value !== 'boolean') {
          return null
        }
        let formatter
        let prop1
        let prop2
        let prop3
        let label
        label = key
        if (typeof value === 'boolean') {
          formatter = this.booleanToIcon
        } else if (key.toLowerCase().endsWith(' (%)')) {
          formatter = this.$filters.percentage
          label = key.replace(' (%)','')
          prop1 = 1
          prop2 = true
        } else if (key.toLowerCase().endsWith(' ($)')) {
          label = key.replace(' ($)','')
          formatter = this.$filters.currency
          prop1 = 2
          prop3 = true
        } else if (key.toLowerCase().includes('percent') || key.toLowerCase().includes('pacing')) {
          formatter = this.$filters.percentage
          prop1 = 0
          prop2 = true
        } else if (key.toLowerCase().includes('cost') || key.toLowerCase().includes('budget') || key.toLowerCase().includes('spend')) {
          formatter = this.$filters.currency
          prop3 = true
        } else {
          formatter = this.$filters.number
          prop2 = true
        }
        return {
          label,
          formatter,
          value,
          prop1,
          prop2,
          prop3
        }
      }).filter(entry => !!entry)
    }
  },
  watch: {
    campaignPlanId: {
      immediate: true,
      async handler (val, old) {
        if (val && val !== old) {
          this.$emit('campaign-loaded', this.campaignPlan?.name || this.campaignPlan?.campaign || null)
          await this.fetchAnalysis()
          if (this.statusBar?.length > 0) {
            this.activeSectionName = this.statusBar[0].section
          }
        }
      }
    }
  },
  methods: {
    setContainerHeight () {
      this.$nextTick(() => {
        if (this.$el && this.insideMuse) {
          // set the container height to the initial height so that it doesn't jump around
          setTimeout(() => {
            this.containerHeight = this.$el.offsetHeight
          }, 100)
        }
      })
    },
    async fetchAnalysis () {
      this.loading = true
      const payload = {
        campaignPlanId: this.campaignPlan.campaignPlanId
      }
      const resp = await this.$res.fetch.analyzeCampaign(payload)
      if (resp) {
        this.analysis = resp
        this.buildStatusBar()
        this.setContainerHeight()
      }
      this.loading = false
    },
    buildStatusBar () {
      const statusBar = this.sections.filter(s => this.analysis[s])
        .map(section => {
          const text = section.replace('Review', '').replace('Partner', '')
          const warning = this.analysis[section]?.checklistMessages?.some(message => message.health === 'NEEDS_REVIEW')
          const alert = this.analysis[section]?.checklistMessages?.some(message => message.health === 'ALERT')
          let status = { text, section }
          if (alert) {
            status = { ...status, variant: 'danger', icon: 'warning' }
          } else if (warning) {
            status = { ...status, variant: 'warning', icon: 'warning' }
          } else {
            status = { ...status, variant: 'success', icon: 'checkmark' }
          }
          return status
        })
      this.statusBar = statusBar
    },
    booleanToIcon (val) {
      if (val) {
        return '<span class="fluency-icon iconic iconic-check text-success"></span>'
      } else {
        return '<span class="fluency-icon iconic iconic-x text-danger"></span>'
      }
    },
    async smxAction (action, index) {
      const newAction = _cloneDeep(action)
      // console.log(index, action)
      // console.log(action.actionUrl)
      // console.log(this.smxToApply[index])

      const newActionUrl = action.actionUrl.split('?keywords=')[0] + '?keywords=' + this.smxToApply[index].join(',') + '&adGroupPlanId=' + action.actionUrl.split('&adGroupPlanId=')[1]

      newAction.actionUrl = newActionUrl
      await this.performAction(newAction, index)
    },
    smxCheckboxAll (e, key) {
      if (e) {
        const checklistArr = []
        this.activeSection.checklistMessages[key].data.forEach(i => {
          checklistArr.push(i.keyword)
        })
        this.$setCompat(this.smxToApply, key, checklistArr)
      } else {
        this.$setCompat(this.smxToApply, key, [])
      }
    },
    smxCheckbox (e, key, keyword) {
      const arr = _cloneDeep(this.smxToApply[key])

      if (e) {
        arr.push(keyword)
      } else {
        const index = arr.indexOf(keyword)
        if (index !== -1) {
          arr.splice(index, 1);
        }
      }

      this.$setCompat(this.smxToApply, key, arr)
    },
    async performAction (action, index) {
      const cfm = await this.$confirm(`Are you sure you want to perform the following action: ${action.actionText}`)
      if (cfm) {
        this.loading = true
        const resp = await this.$res.fetch.generic(action.actionUrl)
        if (resp) {
          this.$toast(`Successfully ${action.actionText}`, 'success')
          this.$setCompat(this.analysis[this.activeSectionName].checklistMessages[index], 'disabled', true)
        }
        this.loading = false
      }
    },
    healthTextMap (health) {
      switch (health) {
        case 'IDEA':
          return 'Suggestion'
        default:
          return health
      }
    },
    toggleCollapsibleCard (key) {
      // THIS METHOD CONTAINS SOME TEMPORARY LOADING STUFF FOR AN `SMX` DEMO IN JUNE 2023
      if (!this.showCollapsibleCard?.[key]) { // open
        this.$setCompat(this.loadingCollapsibleCard, key, true)

        // const checklistArr = []
        // this.activeSection.checklistMessages[key].data.forEach(i => {
        //   checklistArr.push(i.keyword)
        // })
        // this.$setCompat(this.smxToApply, key, checklistArr)
        this.$setCompat(this.smxToApply, key, [])

        _delay(function (v) {
          v.$setCompat(v.showCollapsibleCard, key, !v.showCollapsibleCard?.[key])
          v.$setCompat(v.loadingCollapsibleCard, key, false)
        }, 1250, this)
      } else { // close
        this.$setCompat(this.loadingCollapsibleCard, key, false)
        this.$setCompat(this.smxToApply, key, null)
        this.$setCompat(this.showCollapsibleCard, key, !this.showCollapsibleCard?.[key])
      }
    }
  }
}
</script>

<style lang="scss">
.campaign-analysis {
  .status-bar-container {
    container-type: inline-size;
  }
  .status-indicator {
    height: 30px;
    width: 30px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 10px;
  }
  .status-navigation {
    /* width: 100px; */
    flex: 1 0 auto;
    border-bottom: 3px solid transparent;
    cursor:pointer;
    &:hover {
      border-bottom-color: $fluency-lightergray;
    }

    @container (max-width: 375px) {
      span:nth-child(2) {
        display: none;
      }
    }
  }
  .border-top-success {
    border-top-color: $success;
  }
  .border-top-warning {
    border-top-color: $warning;
  }
  .border-top-danger {
    border-top-color: $danger;
  }
}
</style>
